import * as React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import Seo from '../components/seo'

const Articles = ({ data }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <>
      <Seo
        pageTitle={'Articles'}
        pageDescription={`Here are some useful articles written by us.
    `}
      />
      <Layout>
        <main className='container-fluid'>
          <div className='row justify-content-center'>
            <div className='col-md-8 col-lg-6'>
              <h1 className='pt-5'>Articles</h1>
              <h2 className='lead fs-3'>
                Here are some useful articles written by us.
              </h2>
              {posts.map(post => {
                return (
                  <div key={post.id} className='container py-5'>
                    <div className='row justify-content-between'>
                      <div className='col-md-auto'>
                        <h3>
                          <Link to={post.fields.slug}>
                            {post.frontmatter.title}
                          </Link>
                        </h3>
                      </div>
                      <div className='col-md-auto text-muted fs-6'>
                        {post.frontmatter.date}
                      </div>
                    </div>
                    <p className='text-muted'>{post.excerpt}</p>
                    <div className='row fs-6 justify-content-between'>
                      <div className='col-auto text-muted'>
                        {post.timeToRead} mins to read
                      </div>
                      <div className='col-auto fs-3'>
                        <Link to={post.fields.slug}>read more</Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </main>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        excerpt(pruneLength: 240)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "dddd DD MMMM YYYY")
          title
        }
        timeToRead
      }
    }
  }
`

export default Articles
